class AssetTabs extends HTMLElement {

  constructor() {
    super();
  }

  connectedCallback() {
    this.tabs = this.querySelectorAll('.asset-tab');
    this.tabPanels = this.querySelectorAll('.asset-tabpanel');

    this.tabs.forEach((tab) => {
      tab.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();

        // deactive other tabs
        this.tabs.forEach((element) => {
          if (element != tab) {
            element.parentElement.classList.remove('tab-active');
          }
        });

        // toggle clicked tab
        const tabParent = tab.parentElement;
        tabParent.classList.toggle('tab-active');
      });
    });
  }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('asset-tabs', AssetTabs);
}
