export default class Header {

  constructor() {
    this.html = document.querySelector('html');

    window.addEventListener('scroll', (event) => {
      this.scrollHandler();
    });
    this.scrollHandler()

    window.addEventListener('resize', (event) => {
      this.updateHeaderHeight();
    });

    this.updateHeaderHeight()
  }

  scrollHandler() {
    const header = document.querySelector('.header');
    header.classList.toggle('has-scrolled', this.html.scrollTop > header.clientHeight);
  }

  updateHeaderHeight() {
    const header = document.querySelector('.header');
    this.html.style.setProperty('--header-height', header.clientHeight + 'px');
  }

}
