import once from "@drupal/once";
import Swiper from 'swiper';
import { Pagination } from "swiper/modules"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"

export default class Quotes {

  constructor(selector) {
    const elements = once('quotes', selector);
    elements.forEach(element => {
      const swiper = new Swiper(element.querySelector('.swiper-quotes'), {
        modules: [Pagination],
        pagination: {
          el: '.swiper-pagination-quotes',
          clickable: true,
          bulletClass: 'block size-3 md:size-4 rounded-full border border-theme-copy transition-colors',
          bulletActiveClass: 'bg-theme-copy',
        },
        slidesPerView: 1,
        speed: 750,
        autoHeight: false,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        }
      });

    });


  }

}
