import once from "@drupal/once";
import Swiper from 'swiper';
import { EffectFade, Autoplay } from "swiper/modules"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"

export default class Imageslider {

  constructor(selector) {
    const elements = once('imageslider', selector);
    elements.forEach(element => {
      const swiper = new Swiper(element.querySelector('.swiper-images'), {
        modules: [EffectFade, Autoplay],
        autoplay: true,
        speed: 3000,
        effect: "fade",
        fadeEffect: { crossFade: true },
      });
    });


  }

}
