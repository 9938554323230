import { gsap, ScrollTrigger, DrawSVGPlugin, Power1 } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);

import Swiper from 'swiper';
import { Pagination } from "swiper/modules"

class AssetFacts extends HTMLElement {

  constructor() {
    super();
  }

  connectedCallback() {
    this.factsIntro = this.querySelector('.facts-intro');
    this.factsMoreTrigger = this.querySelector('.facts-more-trigger');
    this.factsText = this.querySelector('.facts-text');

    this.factsArcsAnimationPlayed = false;
    this.factsArcs = this.querySelector('.facts-arcs');
    this.factsArcsIcons = this.querySelectorAll('.facts-arcs-icon');
    this.factsArcsArcs = this.querySelector('.facts-arcs-arcs');
    this.factsArcsLabels = this.querySelectorAll('.facts-arcs-label');

    this.factsFacts = this.querySelector('.facts-facts');
    this.factsSwiperInit = false;
    this.factsSwiperInstance = null;

    // MORE TRIGGER
    this.factsMoreTrigger.addEventListener('click', (event) => {
      this.classList.toggle('active');
      this.factsText.classList.toggle('hidden');
      this.factsFacts.classList.toggle('lg:!grid');
      this.factsFacts.classList.toggle('lg:!hidden', !this.factsFacts.classList.contains('lg:!grid'));
    });


    // SWIPER
    window.addEventListener('resize', (event) => {
      this.resizeHandler();
    });
    this.resizeHandler();


    // DRAW GRAPHIC
    this.setupArcsAnimation();
    ScrollTrigger.create({
      // markers: true,
      trigger: this.factsArcs,
      id: 'facts-arcs',
      start: "top bottom",
      end: "bottom top",
      onEnter: (self) => {
        this.playArcsAnimation();
      },
      onEnterBack: (self) => {
        this.playArcsAnimation();
      },
    });
  }


  setupArcsAnimation() {
    this.factsArcsTimeline = gsap.timeline({
      paused: true,
      callbackScope: this,
      onComplete: () => {
        this.factsArcsAnimationPlayed = true;
      }
    });

    // ARCS
    this.factsArcsTimeline.fromTo(this.factsArcsArcs.querySelectorAll('circle'), { drawSVG: "0%" }, { drawSVG: "100%", duration: 4, stagger: 0.5, ease: Power1.easeInOut });

    // ICONS
    this.factsArcsIcons.forEach((icon) => {
      this.factsArcsTimeline.fromTo(icon.querySelectorAll('rect, line, polyline, circle, path'), { drawSVG: "0%" }, { drawSVG: "100%", duration: 0.5, stagger: 0.1, ease: Power1.easeInOut }, '-=2');
    });

    this.factsArcsTimeline.fromTo(this.factsArcsLabels, { opacity: 0 }, { opacity: 1, duration: 1, stagger: 0.25, ease: Power1.easeInOut }, '-=1.5');



  }


  playArcsAnimation() {
    if (this.factsArcsAnimationPlayed) {
      return;
    }
    this.factsArcsTimeline.play();
  }


  resizeHandler() {
    const mobile = window.matchMedia('(min-width: 0px) and (max-width: 1024px)');
    const desktop = window.matchMedia('(min-width: 1025px)');

    if (desktop.matches && this.swiperInstance) {
      this.factsSwiperInit = false;
      this.swiperInstance.destroy();
      this.swiperInstance = null;
    }

    if (mobile.matches && !this.factsSwiperInit) {
      this.factsSwiperInit = true;
      this.swiperInstance = new Swiper(this.querySelector('.swiper-facts'), {
        modules: [Pagination],
        pagination: {
          el: '.swiper-pagination-facts',
          clickable: true,
          bulletClass: 'block size-3 md:size-4 rounded-full border border-theme-copy transition-colors',
          bulletActiveClass: 'bg-theme-copy',
        },
        slidesPerView: 1,
        breakpoints: {
          1: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 0,
          }
        },
        speed: 750,
        autoHeight: false,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        }
      });
    }
  }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('asset-facts', AssetFacts);
}
