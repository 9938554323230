import animateScrollTo from 'animated-scroll-to';

export default class Jumpmenu {

  constructor(selector) {
    const body = document.querySelector('body');
    const navigationLinks = document.querySelectorAll('.navigation-scroll-link');

    const navigationLinksArray = [...navigationLinks].map((link) => {
      link.addEventListener('click', (event) => {
        const target = document.querySelector(link.hash + '-target');
        if (target && body.classList.contains('is-home')) {
          event.preventDefault();
          const header = document.querySelector('.header');
          const bodyRect = document.body.getBoundingClientRect();
          const elemRect = target.getBoundingClientRect();
          const _options = {
            minDuration: 750,
            maxDuration: 3000,
            verticalOffset: -(header.clientHeight) + 1,
          };
          animateScrollTo((elemRect.top - bodyRect.top), _options);
        }
      });
    });
  }

}
