import barba from '@barba/core';
import { gsap } from "gsap/all";
import animateScrollTo from 'animated-scroll-to';
// import Cookies from 'js-cookie';

import Header from "./header";
import NavMobile from "./nav-mobile";
import Reveal from "./reveal";
import Carousel from './carousel';
import Imageslider from './imageslider';
import Quotes from './quotes';
import Jumpmenu from "./jumpmenu";
import AssetAccordion from "./accordion";

export default class BarbaJs {

  constructor() {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    const languageSwitcher = document.querySelector('.language-switcher');
    // const navigation = document.querySelector('.navigation--header');
    // const navigationLinks = document.querySelectorAll('.navigation--header-link');
    // const navigationMeta = document.querySelector('.meta--navigation');
    history.scrollRestoration = "manual";
    var scrollPosY = [0];


    barba.init({
      timeout: 7500,

      debug: true,
      cacheIgnore: true,
      prefetchIgnore: true,

      requestError: (trigger, action, url, response) => {
        console.log({
          trigger: trigger,
          action: action,
          url: url,
          response: response,
        })
      },

      prevent: ({ el }) => el.classList && (el.classList.contains('prevent-barba') || el.classList.contains('active')),

      transitions: [
        {
          name: 'default-transition',

          once(data) {
            setTimeout(() => {
              new Header();
              new NavMobile();
              new AssetAccordion('.js--accordion');
              new Jumpmenu();
              new Carousel('.js--carousel');
              new Imageslider('.js--imageslider');
              new Quotes('.js--quotes');
              new Reveal('.js--reveal');
            }, 1);

            return gsap.to(body, {
              opacity: 1,
              duration: 1,
              delay: 0.25,
              onComplete: () => {
                // scroll to anchor
                if (window.location.hash) {
                  const target = document.querySelector(window.location.hash + '-target');
                  if (target) {
                    const header = document.querySelector('.header');
                    const bodyRect = document.body.getBoundingClientRect();
                    const elemRect = target.getBoundingClientRect();

                    setTimeout(() => {
                      const _options = {
                        minDuration: 500,
                        maxDuration: 3000,
                        verticalOffset: -(header.clientHeight) + 1,
                      };
                      animateScrollTo((elemRect.top - bodyRect.top), _options).then((hasScrolledToPosition) => {
                        // remove hash
                        history.pushState("", document.title, window.location.pathname + window.location.search);
                      });
                    }, 250);
                  }
                }
              }
            });
          },

          beforeLeave(data) {
            if (data.trigger !== 'back') {
              scrollX = barba.history.current.scroll.x;
              scrollY = barba.history.current.scroll.y;
            }

            return gsap.to(data.current.container, {
              opacity: 0,
              duration: (data.trigger === 'back' || data.trigger === 'forward') ? 0 : 0.5,
              delay: 0,
              // onStart: () => {
              //   navigationMeta.style.opacity = 0;
              // },
            });
          },

          afterLeave(data) {
            // update body classes
            body.classList.toggle('is-home', data.next.namespace === 'home');
            body.classList.toggle('not-home', data.next.namespace !== 'home');
          },

          beforeEnter(data) {
            html.lang = data.next.container.dataset.langCode;
            languageSwitcher.href = data.next.container.dataset.translationUrl;
            languageSwitcher.hreflang = data.next.container.dataset.translationCode;
            languageSwitcher.textContent = data.next.container.dataset.translationLanguage;

            data.current.container.remove();
            data.next.container.style.opacity = 0;
          },


          enter(data) {
            if (data.trigger !== "back") {
              scrollPosY.push(barba.history.current.scroll.y);
            }

            // reinit JS
            setTimeout(() => {
              new Header();
              new NavMobile();
              new AssetAccordion('.js--accordion');
              new Jumpmenu();
              new Carousel('.js--carousel');
              new Imageslider('.js--imageslider');
              new Quotes('.js--quotes');
              new Reveal('.js--reveal');
            }, 1);
          },

          afterEnter(data) {
            if (data.trigger == 'back') {
              setTimeout(() => {
                window.scrollTo(0, scrollPosY.pop())
              }, 500);
            } else {
              window.scrollTo(0, 0);
            }

            return gsap.to(data.next.container, {
              opacity: 1,
              duration: 1,
              delay: 0.75,
              onComplete: () => {
                // scroll to anchor
                if (window.location.hash) {
                  const target = document.querySelector(window.location.hash + '-target');
                  if (target) {
                    const header = document.querySelector('.header');
                    const bodyRect = document.body.getBoundingClientRect();
                    const elemRect = target.getBoundingClientRect();
                    setTimeout(() => {
                      const _options = {
                        minDuration: 500,
                        maxDuration: 3000,
                        verticalOffset: -(header.clientHeight) + 1,
                      };
                      animateScrollTo((elemRect.top - bodyRect.top), _options).then((hasScrolledToPosition) => {
                        // remove hash
                        history.pushState("", document.title, window.location.pathname + window.location.search);
                      });
                    }, 500);
                  }
                }
              }
            });
          }

        },
      ],

    });
  }

}
