import once from "@drupal/once";
import Swiper from 'swiper';
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
// import { EffectFade, Autoplay } from "swiper/modules"

import "swiper/css"
// import "swiper/css/autoplay"
// import "swiper/css/effect-fade"

export default class Carousel {

  constructor(selector) {
    const elements = once('carousel', selector);
    elements.forEach(element => {
      const swiper = new Swiper(element.querySelector('.swiper-carousel'), {
        modules: [],
        // autoplay:{
        //   disableOnInteraction: false,
        //   pauseOnMouseEnter: false,
        // },
        // effect: "fade",
        speed: 2000,
        loop : true,
        keyboard : {
          enabled: false,
          // onlyInViewport: true,
        },
        init: () => {
          ScrollTrigger.refresh();
        }
      });
    });


  }

}
